import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import LogoImage from '../../assets/images/logo memory.png';

export default function () {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="bg-transparent transparent-navbar">
      <Container>
        <Navbar.Brand href="/">
          <img width="100px" src={LogoImage} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link href="/">О продукте</Nav.Link>
            <Nav.Link href="/">Тарифы</Nav.Link>
            <Nav.Link href="/articles">Блог</Nav.Link>
            <Nav.Link href="/">Рейтинг студентов</Nav.Link>
            <Nav.Link href="/">Наша команда</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
