import { bindActionCreators } from 'redux';
import { connect as reduxConnect } from 'react-redux';
import RatingArchive from '../../components/RaringArchive/RaringArchive';
import * as RagingArchiveAction from '../../actions/main/rating-archive';

const mapStateToProps = (state) => ({
  ...state.ratingArchive,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...RagingArchiveAction,
  }, dispatch),
});
export default reduxConnect(mapStateToProps, mapDispatchToProps)(RatingArchive);
