import { merge } from '../utils/operations';
import createReducer from '../utils/base';
import {
  GET_ARCHIVE_RATING_ERROR,
  GET_ARCHIVE_RATING_FETCH,
  GET_ARCHIVE_RATING_SUCCESS,
  SET_MONTH,
  SET_YEAR,
  SET_AVAILABLE_YEARS_AND_MONTHS,
} from '../../actions/main/rating-archive';

const INITIAL_STATE = {
  students: [],
  availableYears: [],
  availableMonths: {},
  year: undefined,
  month: undefined,
  isLoading: false,
};

export default createReducer({
  [GET_ARCHIVE_RATING_FETCH]: (state) => merge(state, {
    isLoading: true,
  }),
  [GET_ARCHIVE_RATING_SUCCESS]: (state, action) => merge(state, {
    isLoading: false,
    students: action.students,
  }),
  [GET_ARCHIVE_RATING_ERROR]: (state) => merge(state, {
    isLoading: false,
  }),
  [SET_YEAR]: (state, action) => merge(state, {
    year: action.year,
  }),
  [SET_MONTH]: (state, action) => merge(state, {
    month: action.month,
  }),
  [SET_AVAILABLE_YEARS_AND_MONTHS]: (state, action) => ({
    ...state,
    ...action,
  }),
}, INITIAL_STATE);
