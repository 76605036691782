import React from 'react';
import '../../styles/SighnUp.css';
import images from '../Main/Images';

export default function CreateSchool() {
  return (
    <>
      <div className="sighnup-root">
        <div className="signup-card">
          <form>
            <div className="sighnup-container">
              <p>Создать школу</p>
              {/* <hr> */}
              <label htmlFor="name"><b>Имя</b></label>
              <input type="text" placeholder="Введите имя" name="name" required />
              <label htmlFor="first-name"><b>Фамилия</b></label>
              <input type="text" placeholder="Введите фамилия" name="first" required />

              <label htmlFor="email"><b>Email</b></label>
              <input type="text" placeholder="Введите Email" name="email" required />
              <label htmlFor="ext"><b>Телефон</b></label>
              <input type="text" placeholder="Введе телефонный номер" name="phone" required />
              <label htmlFor="username"><b>Имя пользователя</b></label>
              <input type="text" placeholder="Имя пользователя" name="username" required />
              <label htmlFor="schoolName"><b>Название школы</b></label>
              <input type="text" placeholder="Название школы" name="schoolName" required />
              <label htmlFor="description"><b>Описание</b></label>
              <div contentEditable="true" className="school-description" placeholder="Описание" />

              <div className="div-row-rates-items">
                <div className="yellowbtn-img-div">
                  <img className="signupbtn-item" src={images.yellowButton} alt="" />
                  <div
                    className="signupbtn-text"
                  >
                    Cancel
                  </div>
                </div>
                <div className="bluebtn-img-div">
                  <img className="signupbtn-item" src={images.blueButton} alt="" />
                  <div
                    className="signupbtn-text"
                  >
                    Sign Up
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
