import React from 'react';
import '../../../styles/DevelopersSection/DevelopersSection.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rysbaiAvtr from '../../../assets/images/rysbai-avtr.jpg';
import nursAvtr from '../../../assets/images/nurs.jpg';
import belekAvtr from '../../../assets/images/belek-aki.jpg';
import amanAvtr from '../../../assets/images/amano.jpg';
import muratAvtr from '../../../assets/images/murat.jpg';

const developer = (fullName, position, imageUrl) => ({
  fullName,
  position,
  imageUrl,
});

const developers = [
  developer('Жолдошбеков Нурсултан', 'Full-Stack разработчик & Team Lead', nursAvtr),
  developer('Галдыбаев Белек', 'Front-End разработччик', belekAvtr),
  developer('Абдрасулов Мурат', 'Front-End разработчик', muratAvtr),
  developer('Ибраев Искендер Аман', 'Front-End разработчик', amanAvtr),
  developer('Аманбай уулу Рысбай', 'Back-End разработчик', rysbaiAvtr),

];

export default function DevelopersSection() {
  return (
    <div className="developers-section">
      <div className="section-title-container">
        <div>
          <FontAwesomeIcon icon={faPlay} className="icon" />
        </div>
        <div className="section-title second-child">
          <p>Наша команда</p>
        </div>
      </div>
      <Container className="mb-5">
        <Row className="justify-content-md-center justify-content-sm-center">
          {developers.map((staff, index) => (
            <Col key={index} md={4} sm={12}>
              <ProfileCard staff={staff} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

function ProfileCard({ staff }) {
  return (
    <Row className="profile-container align-items-center mb-4">
      <Col className="avatar-col">
        <img src={staff.imageUrl} alt="" className="avatar" />
      </Col>
      <Col>
        <div className="profile-info">
          <h5>{staff.fullName}</h5>
          <p>{staff.position}</p>
        </div>
      </Col>
    </Row>
  );
}
