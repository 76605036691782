import { merge } from 'extend-merge';
import createReducer from '../utils/base';
import {
  GET_ARTICLES_ERROR,
  GET_ARTICLES_SUCCESS, RETRIEVE_ARTICLE_ERROR,
  RETRIEVE_ARTICLE_SUCCESS,
  SET_PAGE,
  SET_PAGINATION_COUNT,
} from '../../actions/blog/blog';

const INITIAL_STATE = {
  articles: [],
  retrievedArticle: {},
  total: null,
  page: 1,
  paginationCount: 4,
  getArticlesError: '',
  retrieveArticleError: '',
};

export default createReducer({
  [GET_ARTICLES_SUCCESS]: (state, action) => ({
    ...state,
    ...action,
  }),
  [GET_ARTICLES_ERROR]: (state, action) => merge({}, state, { getArticlesError: action.error }),
  [RETRIEVE_ARTICLE_SUCCESS]: (state, action) => ({
    ...state,
    ...action,
  }),
  [RETRIEVE_ARTICLE_ERROR]: (state, action) => merge({}, state, { retrieveArticleError: action.error }),
  [SET_PAGE]: (state, action) => merge({}, state, { ...action }),
  [SET_PAGINATION_COUNT]: (state, action) => merge({}, state, { ...action }),
}, INITIAL_STATE);
