import createReducer from '../utils/base';
import { GET_PACKAGE_TYPES_FAIL, GET_PACKAGE_TYPES_SUCCESS } from '../../actions/main/package_types';

const INITIAL_STATE = {
  packageTypes: [],
  getPackageTypesError: '',
};

export default createReducer({
  [GET_PACKAGE_TYPES_SUCCESS]: (state, action) => ({
    ...state,
    packageTypes: action.packageTypes,
  }),
  [GET_PACKAGE_TYPES_FAIL]: (state, action) => ({
    ...state,
    error: action.error,
  }),
}, INITIAL_STATE);
