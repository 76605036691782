import React from 'react';
import '../../../styles/BlogSection/BlogSection.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArticleCard } from '../../common/ArticleCard';

export default function BlogSection({ blog }) {
  return (
    <div className="home-container blog-section">
      <div className="component second-component">
        <div className="column-style">
          <div className="section-title-container">
            <div>
              <FontAwesomeIcon icon={faPlay} className="icon" />
            </div>
            <div className="section-title second-child">
              <p>Недавние посты</p>
            </div>
            <div>
              <a href="/articles">Смотреть все</a>
            </div>
          </div>
          <Container>
            <Row>
              {blog.articles.map((article) => (
                <Col lg={3} md={3} sm={12} className="mb-4" key={article.id}>
                  <ArticleCard article={article} key={article.id} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>

    </div>
  );
}
