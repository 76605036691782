import React from 'react';
import '../../../styles/RatingSection.css';
import { MatchMediaHOC } from 'react-match-media';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomText from './BottomText';

const RatingsSection = ({ ratings }) => (
  <div className="component second-component">
    <div className="column-style ratings-section">
      <div className="section-title-container">
        <div>
          <FontAwesomeIcon icon={faPlay} className="icon" />
        </div>
        <div className="section-title second-child">
          <p>Рейтинг студентов за прошлый месяц</p>
        </div>
      </div>
      <RatingsDesktopVersion ratings={ratings} />
      <RatingsMobileVersion ratings={ratings} />
      <BottomText />
    </div>
  </div>
);
export default RatingsSection;

const RatingsDesktopVersion = MatchMediaHOC(RatingsDesktop, '(min-width: 820px)');
const RatingsMobileVersion = MatchMediaHOC(RatingsMobile, '(max-width: 820px)');

function RatingsDesktop(props) {
  const getStudent = (index) => (props.ratings[index] || {});
  return (
    <Container>
      <Row className="align-items-center justify-items-between">
        <Col md={2}>
          <FirstPlace student={getStudent(0)} />
        </Col>
        <Col md={10}>
          <Row md={3}>
            {[1, 2, 3].map((index) => (
              <Col key={index}>
                <OtherPlacesCard student={getStudent(index)} place={index + 1} />
              </Col>
            ))}
          </Row>
          <Row md={3}>
            {[4, 5, 6].map((index) => (
              <Col key={index}>
                <OtherPlacesCard student={getStudent(index)} place={index + 1} />
              </Col>
            ))}
          </Row>
          <Row md={3}>
            {[7, 8, 9].map((index) => (
              <Col key={index}>
                <OtherPlacesCard student={getStudent(index)} place={index + 1} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col xs={5}>
          <a href="/rating-archive" className="more-link">Победители прошлых месяцев</a>
        </Col>
      </Row>
    </Container>
  );
}

function RatingsMobile(props) {
  const getStudent = (index) => (props.ratings[index] || {});
  return (
    <Container fluid>
      <Row className="align-items-center justify-content-between">
        <Col xs={4}>
          <FirstPlace student={getStudent(0)} />
        </Col>
        <Col xs={8}>
          <OtherPlacesCard student={getStudent(1)} place={2} />
          <div className="ml-4">
            <OtherPlacesCard student={getStudent(2)} place={3} />
          </div>
          <OtherPlacesCard student={getStudent(3)} place={4} />
        </Col>
      </Row>
      {[4, 5, 6, 7, 8, 9].map((index) => (
        <Row className="justify-content-center" key={index}>
          <OtherPlacesCard student={getStudent(index)} place={index + 1} />
        </Row>
      ))}
    </Container>
  );
}

function FirstPlace({ student }) {
  return (
    <Container fluid className="first-place-card-container">
      <Row>
        <Col className="place-number-col">
          <div className="place-number">
            <span>1</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="winner-info">
          <span className="full-name">{`${student.first_name} ${student.last_name}`}</span>
          <span className="school">{student.school_name}</span>
          <span className="points">
            {student.rating}
            {' '}
            баллов
          </span>
        </Col>
      </Row>
    </Container>
  );
}

function OtherPlacesCard({ student, place }) {
  return (
    <Container className="places-container">
      <Row className="justify-content-start align-items-center">
        <Col xs={3}>
          <div className="place-number">
            <span>{place}</span>
          </div>
        </Col>
        <Col xs={8} className="winner-info">
          <span className="full-name">{`${student.first_name} ${student.last_name}`}</span>
          <span className="school">{student.school_name}</span>
          <span className="points">
            {student.rating || 0}
            {' '}
            баллов
          </span>
        </Col>
      </Row>
    </Container>
  );
}
