import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import timerMiddleware from 'redux-timer-middleware';
import * as Sentry from '@sentry/browser';
import reducer from './reducers';
import Root from './containers/Root';

export const MENTAL_HOST = process.env.REACT_APP_MENTAL_HOST || 'https://memorymee.org';

if (process.env.REACT_APP_ENV === 'prod') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_SDK_URL });
}

export const history = createBrowserHistory();

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk, timerMiddleware)),
);

ReactDOM.render(
  <Root
    history={history}
    store={store}
  />,
  document.getElementById('root'),
);
