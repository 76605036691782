import axios from 'axios';
import moment from 'moment';

export const TYPE_PREFIX = 'BLOG';
export const GET_ARTICLES_SUCCESS = `${TYPE_PREFIX}/GET_ARTICLES_SUCCESS`;
export const GET_ARTICLES_ERROR = `${TYPE_PREFIX}/GET_ARTICLE_ERROR`;
export const SET_PAGE = `${TYPE_PREFIX}/SET_PAGE`;
export const SET_PAGINATION_COUNT = `${TYPE_PREFIX}/SET_PAGINATION_COUNT`;
export const RETRIEVE_ARTICLE_SUCCESS = `${TYPE_PREFIX}/RETRIEVE_ARTICLE_SUCCESS`;
export const RETRIEVE_ARTICLE_ERROR = `${TYPE_PREFIX}/RETRIEVE_ARTICLE_ERROR`;

export const getArticles = () => (dispatch, getState) => {
  const state = getState();
  const { page, paginationCount } = state.blog;
  const url = `/api/articles?page=${page}&count=${paginationCount}`;

  axios.get(url)
    .then((response) => {
      dispatch({
        type: GET_ARTICLES_SUCCESS,
        ...response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ARTICLES_ERROR,
        error,
      });
    });
};

export const retrieveArticle = (articleId) => (dispatch) => {
  const url = `/api/articles/${articleId}`;

  axios.get(url)
    .then((response) => {
      const article = response.data;
      article.created_at = moment(article.created_at).format('MMM Do, YYYY');
      dispatch({
        type: RETRIEVE_ARTICLE_SUCCESS,
        retrievedArticle: article,
      });
    })
    .catch((error) => dispatch({
      type: RETRIEVE_ARTICLE_ERROR,
      error,
    }));
};

export const setPage = (page) => ({
  type: SET_PAGE,
  page,
});

export const setPaginationCount = (paginationCount) => ({
  type: SET_PAGINATION_COUNT,
  paginationCount,
});
