import React from 'react';
import '../../../styles/RatingSection.css';
import { MatchMediaHOC } from 'react-match-media';
import images from '../Images';

const BottomText = () => (
  <div className="ask-text-in-rating">
    <img className="horizontal-line" src={images.whiteLine} alt="" />
    <div className="ask-text">
      <p>КАК ОПРЕДЕЛЯЕТСЯ РЕЙТИНГ УЧАСТНИКОВ?</p>
    </div>
    <div className="answer-text-in-rating">
      <p>
        Оценка практических навыков устного счета проводится по 2 критериям:
        правильность (% правильно выполненных заданий от общего количества
        выданных) и скорость арифметических вычислений в уме (длительность
        интервалов в секундах).
        В ходе текущей и промежуточной оценки может быть оценено
        достижение таких коммуникативных и регулятивных действий, как
        взаимодействие с партнером: ориентация на партнера, умение слушать и
        слышать собеседника; стремление учитывать и координировать различные
        мнения и позиции в отношении объекта, действия, события и др.

      </p>
    </div>
  </div>
);
export default MatchMediaHOC(BottomText, '(min-width: 910px)');
