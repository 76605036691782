import React from 'react';
import '../../styles/style.css';
import { MatchMediaHOC } from 'react-match-media';

const AppNavigates = ({ actions }) => (

  <div className="appbar-text-btn-container">
    <button className="app-bar-text-btn" onClick={() => actions.handlePage(1)}>Продукт</button>
    <button className="app-bar-text-btn" onClick={() => actions.handlePage(2)}>Тарифы</button>
    <button className="app-bar-text-btn" onClick={() => actions.handlePage(3)}>Блог</button>
    <button onClick={() => actions.handlePage(4)} className="app-bar-text-btn">Рейтинг</button>
    <button onClick={() => actions.handlePage(5)} className="app-bar-text-btn">Наша команда</button>
  </div>
);

export default MatchMediaHOC(AppNavigates, '(min-width: 1010px)');
