import React from 'react';
import '../../../styles/PackagesSection/PackagesSection.css';
import Row from 'react-bootstrap/Row';
import { Card } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faUser, faClock } from '@fortawesome/free-solid-svg-icons';

export default function PackagesSection({ packageTypes }) {
  return (
    <div className="home-container">
      <div className="component second-component">
        <div className="column-style">
          <div className="section-title-container">
            <div>
              <FontAwesomeIcon icon={faPlay} className="icon" />
            </div>
            <div className="section-title second-child">
              <p>Пакеты</p>
            </div>
          </div>
          <Container>
            <Row className="justify-content-center">
              {packageTypes.map((packageType) => (
                <Col key={packageType.id} md={3} sm={12} className="mb-4">
                  <PackageTypeCard packageType={packageType} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>

    </div>
  );
}

function PackageTypeCard({ packageType }) {
  return (
    <Card className="package-type-card">
      <Card.Body className="package-type-card-body">
        <span className="name">{packageType.name}</span>
        <span className="price">
          {packageType.price}
          {' '}
          сом
        </span>
        <div>
          <FontAwesomeIcon icon={faUser} className="description-icon" />
          <span className="accounts_quantity">
            {packageType.accounts_quantity}
            {' '}
            аккаунтов
          </span>
        </div>
        <div>
          <FontAwesomeIcon icon={faClock} className="description-icon" />
          <span className="item_month_duration">
            на
            {packageType.item_month_duration}
            {' '}
            месяцев
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}
