import { merge } from 'extend-merge';
import createReducer from '../utils/base';

import {
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
} from '../../actions/main/rating-page';
import { GET_VERSION_SUCCESS, HANDLE_PAGE } from '../../actions/main/main-page';

const INITIAL_STATE = {
  ratings: [],
  currentPage: null,
  buildNumber: null,
};

export default createReducer({
  [GET_STUDENTS_SUCCESS]: (state, action) => merge({}, state, {
    ratings: action.ratings,
  }),
  [GET_STUDENTS_FAIL]: (state, action) => merge({}, state, { error: action.error }),
  [HANDLE_PAGE]: (state, action) => merge({}, state, {
    currentPage: action.page,
  }),
  [GET_VERSION_SUCCESS]: (state, action) => merge({}, state, { buildNumber: action.version }),
}, INITIAL_STATE);
