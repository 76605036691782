import { bindActionCreators } from 'redux';
import { connect as reduxConnect } from 'react-redux';
import NewsPage from '../../components/NewsPage/NewsPage';
import { getArticles, retrieveArticle } from '../../actions/blog/blog';

const mapStateToProps = (state) => ({
  ...state.blog,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    retrieveArticle,
    getArticles,
  }, dispatch),
});

export default reduxConnect(mapStateToProps, mapDispatchToProps)(NewsPage);
