import React from 'react';
import { Container, Pagination } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArticleCard } from '../common/ArticleCard';
import '../../styles/AllArticlesPage/AllArticlesPage.css';
import NavBar from '../common/NavBar';

export default function ({
  articles, total, page, paginationCount, actions,
}) {
  React.useEffect(() => {
    actions.getArticles();
  }, [
    actions,
    actions.getArticles,
    page,
    paginationCount,
  ]);

  React.useEffect(() => {
    actions.setPaginationCount(9);
  }, [actions, actions.setPaginationCount]);

  const pagesCount = Math.ceil(total / paginationCount);
  let previousEllipsis = false;
  let nextEllipses = false;

  const restPageItems = [];
  for (let number = 2; number <= pagesCount - 1; number++) {
    if (number + 2 <= page && previousEllipsis) continue;
    if (number - 2 >= page && nextEllipses) continue;

    if (number + 2 <= page && !previousEllipsis) {
      previousEllipsis = true;
      restPageItems.push(
        <Pagination.Ellipsis onClick={() => actions.setPage(number)} />,
      );
      continue;
    }
    if (number - 2 >= page && !nextEllipses) {
      nextEllipses = true;
      restPageItems.push(
        <Pagination.Ellipsis onClick={() => actions.setPage(number)} />,
      );
      continue;
    }

    restPageItems.push(
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => actions.setPage(number)}
      >
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <div className="all-articles-page">
      <NavBar />
      <Container>
        <Row className="mt-4">
          {articles.map((article) => (
            <Col md={4} lg={4} sm={12} className="mb-4" key={article.id}>
              <ArticleCard article={article} />
            </Col>
          ))}
        </Row>
        <Row className="articles-pagination">
          <Pagination size="md">
            <Pagination.First disabled={page === 1} onClick={() => actions.setPage(1)} />
            <Pagination.Prev disabled={page === 1} onClick={() => actions.setPage(page - 1)} />
            <Pagination.Item
              key={1}
              active={page === 1}
              onClick={() => actions.setPage(1)}
            >
              {1}
            </Pagination.Item>
            {restPageItems}
            {
                            pagesCount !== 1 && (
                            <Pagination.Item
                              key={pagesCount}
                              active={page === pagesCount}
                              onClick={() => actions.setPage(pagesCount)}
                            >
                              {pagesCount}
                            </Pagination.Item>
                            )
                        }
            <Pagination.Next disabled={page === pagesCount} onClick={() => actions.setPage(page + 1)} />
            <Pagination.Last disabled={page === pagesCount} onClick={() => actions.setPage(pagesCount)} />
          </Pagination>
        </Row>
      </Container>
    </div>
  );
}
