import axios from 'axios';
import { MENTAL_HOST } from '../../index';

export const GET_PACKAGE_TYPES_SUCCESS = 'PACKAGE_TYPES/GET_PACKAGE_TYPES_SUCCESS';
export const GET_PACKAGE_TYPES_FAIL = 'PACKAGE_TYPES/GET_PACKAGE_TYPES_FAIL';

export const getPackageTypes = () => (dispatch) => {
  const url = `${MENTAL_HOST}/api/package_types`;

  axios.get(url)
    .then((response) => {
      dispatch({
        type: GET_PACKAGE_TYPES_SUCCESS,
        packageTypes: response.data.slice(0, 4),
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PACKAGE_TYPES_FAIL,
        error,
      });
    });
};
