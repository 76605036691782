import axios from 'axios';
import moment from 'moment';

export const GET_ARCHIVE_RATING_FETCH = 'ARCHIVE_RATING/GET_ARCHIVE_RATING_FETCH';
export const GET_ARCHIVE_RATING_SUCCESS = 'ARCHIVE_RATING/GET_ARCHIVE_RATING_SUCCESS';
export const GET_ARCHIVE_RATING_ERROR = 'ARCHIVE_RATING/GET_ARCHIVE_RATING_ERROR';
export const SET_YEAR = 'ARCHIVE_RATING/SET_YEAR';
export const SET_MONTH = 'ARCHIVE_RATING/SET_MONTH';
export const SET_AVAILABLE_YEARS_AND_MONTHS = 'ARCHIVE_RATING/SET_AVAILABLE_YEARS_AND_MONTHS';

export const getRatingArchive = () => (dispatch, getState) => {
  dispatch({ type: GET_ARCHIVE_RATING_FETCH });
  let {
    year, month, availableYears, availableMonths,
  } = getState().ratingArchive;
  year = year || '';
  month = month || '';

  axios.get(`/api/winners?year=${year}&month=${month}`)
    .then((response) => {
      dispatch({
        type: GET_ARCHIVE_RATING_SUCCESS,
        students: response.data.winners,
      });

      if (availableYears.length === 0 || availableMonths.length === 0) {
        const availableYears = getAvailableYears(response.data.available_date_range);
        const availableMonths = getAvailableMonths(response.data.available_date_range);
        dispatch({
          type: SET_AVAILABLE_YEARS_AND_MONTHS,
          availableYears,
          availableMonths,
        });
        const year = availableYears[availableYears.length - 1];
        const months = availableMonths[year];
        dispatch(setYear(year));
        dispatch(setMonth(months[months.length - 1].code));
      }
    }).catch((error) => {
      dispatch({ type: GET_ARCHIVE_RATING_ERROR });
    });
};

export const setYear = (year) => (dispatch, getState) => {
  const state = getState();
  const { availableMonths } = state.ratingArchive;
  dispatch({
    type: SET_YEAR,
    year,
  });
  const months = availableMonths[year];
  dispatch(setMonth(months[0].code));
};

export const setMonth = (month) => ({
  type: SET_MONTH,
  month,
});

function getAvailableYears({ start, end }) {
  if (!start || !end) return [];

  const startYear = new Date(start).getFullYear();
  const endYear = new Date(end).getFullYear();

  const availableYears = [];
  let year = startYear;

  while (endYear - year >= 0) {
    availableYears.push(
      year,
    );
    year++;
  }

  return availableYears;
}

function getAvailableMonths({ start, end }) {
  if (!start || !end) return [];

  const startDate = new Date(start);
  const endDate = new Date(end);

  const date = startDate;
  let year = date.getFullYear();
  const months = {};
  while (endDate.getFullYear() > year || endDate.getMonth() >= date.getMonth()) {
    if (!months[year]) {
      months[year] = [{
        code: date.getMonth() + 1,
        display: moment(date).format('MMMM'),
      }];
    } else {
      months[year].push({
        code: date.getMonth() + 1,
        display: moment(date).format('MMMM'),
      });
    }

    date.setMonth(date.getMonth() + 1);
    year = date.getFullYear();
  }

  return months;
}
