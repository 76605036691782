import { bindActionCreators } from 'redux';
import { connect as reduxConnect } from 'react-redux';
import { getArticles, setPage, setPaginationCount } from '../../actions/blog/blog';
import AllArticlesPage from '../../components/AllArticlesPage/AllArticlesPage';

const mapStateToProps = (state) => ({
  ...state.blog,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    getArticles,
    setPaginationCount,
    setPage,
  }, dispatch),
});

export default reduxConnect(mapStateToProps, mapDispatchToProps)(AllArticlesPage);
