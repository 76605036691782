import React, { useEffect } from 'react';
import '../../styles/BottomNavigation.css';
import { MatchMediaHOC } from 'react-match-media';
import Images from './Images';

const SideBar = ({ buildNumber, actions }) => {
  const getPagesNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= 6; i++) {
      pageNumbers.push(
        <button className="pager-dot" key={i} onClick={() => actions.handlePage(i - 1)} />,
      );
    }
    return [...pageNumbers];
  };
  useEffect(() => {
    actions.getVersion();
  }, [actions]);

  return (
    <div className="side-bar-class">
      <div className="sidenav">
        <div className="ext-box">
          <div className="side-avatar-icon" />
          <div className="article-box">
            <a href="https://www.facebook.com/intelect.kyrgyzstan.7">
              <img className="side-icon-facebook" alt="" />
            </a>
          </div>
          <div className="article-box">
            <a href="https://www.youtube.com/channel/UC3kR-iKd1UhLiigx9kQZ0yQ">
              <img className="side-icon-youtube" src={Images.iconYoutube} alt="" />
            </a>
          </div>
          <div className="article-box">
            <a href="https://www.instagram.com/memorymee_official/">
              <img className="side-icon-insta" src={Images.iconInsta} alt="" />
            </a>
          </div>
          <div className="article-box">
            <img className="side-icon-whatsapp" src={Images.iconWhatsapp} alt="" />
          </div>
          <div className="pager-container">
            {getPagesNumbers()}
          </div>
          <div className="side-share-container">
            <img className="share-icon" src={Images.shareIcon} alt="" />
          </div>
          <div className="side-bar-build-number">
            {`#${buildNumber}`}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MatchMediaHOC(SideBar, '(min-width: 460px)');
