import React from 'react';
import '../../styles/style.css';
import Images from './Images';

export default function HomeSection() {
  return (
    <div className="home-container">
      <div className="component first-component">
        <div className="img-main-logo">
          <img className="img-icon-home" src={Images.IconHome} alt="homeIcon" />
          <img className="img-title-home" src={Images.homeTitleIcon} alt="homeTitle" />
        </div>
      </div>
    </div>
  );
}
