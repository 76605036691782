import React from 'react';
import { Route, Switch } from 'react-router';
import App from '../components/App';
import MainPage from '../containers/Main/MainPageContainer';
import SignUp from '../components/SignUp/SignUp';
import CreateSchool from '../components/SignUp/CreateSchool';
import RatingArchive from '../containers/RatingArchive/RatingArchive';
import NewsPage from '../containers/NewsPageContainer/NewsPageContainer';
import AllArticlesPageContainer from '../containers/AllArticlesPageContainer/AllArticlesPageContainer';

export default (
  <App>
    <Switch>
      <Route exact path="/" component={MainPage} />
      <Route exact path="/sign-up-person" component={SignUp} />
      <Route exact path="/create-school" component={CreateSchool} />
      <Route exact path="/rating-archive" component={RatingArchive} />
      <Route
        exact
        path="/login"
        component={() => {
          window.location.href = `${process.env.REACT_APP_MENTAL_MAIN_URL}/login`;
          return null;
        }}
      />
      <Route path="/articles/:articleId" component={NewsPage} />
      <Route path="/articles" component={AllArticlesPageContainer} />
    </Switch>
  </App>
);
