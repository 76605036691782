import axios from 'axios';

export const GET_STUDENTS_FAIL = 'RATING_PAGE/GET_STUDENTS_FAIL';
export const GET_STUDENTS_SUCCESS = 'RATING_PAGE/GET_STUDENTS_SUCCESS';

export const getStudentRatings = () => (dispatch, getState) => {
  const baseUrl = `${process.env.REACT_APP_MENTAL_HOST}/api/rating_students?page=1`;
  axios.defaults.headers = {};
  axios.get(baseUrl)
    .then((response) => {
      dispatch({
        type: GET_STUDENTS_SUCCESS,
        ratings: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_STUDENTS_FAIL,
        error: error.response.data.error,
      });
    });
};
