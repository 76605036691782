import React from 'react';
import '../../styles/NewsPage/NewsPage.css';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { ArticleCard } from '../common/ArticleCard';
import NavBar from '../common/NavBar';

export default function NewsPage({
  actions, retrievedArticle, articles, match,
}) {
  const { articleId } = match.params;

  React.useEffect(() => {
    actions.retrieveArticle(articleId);
    actions.getArticles();
  }, [actions, actions.retrieveArticle, actions.getArticles, articleId]);

  return (
    <div className="news-page-container">
      <NavBar />
      <Container className="mt-4">
        <Row>
          <Col md={9} lg={9} sm={12} className="pb-4">
            <Card>
              <Card.Body className="article-card-body">
                <h2>{retrievedArticle.title}</h2>
                <p className="article-short-description">{retrievedArticle.short_description}</p>
                <p className="article-created-at">{retrievedArticle.created_at}</p>
                <img className="article-image" src={retrievedArticle.image} alt="article" />
                <div
                  className="article-payload mt-4"
                  dangerouslySetInnerHTML={{ __html: retrievedArticle.payload }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={3} sm={12} className="recent-articles">
            {articles.filter((article) => article.id !== retrievedArticle.id)
              .map((article) => (
                <Row key={article.id}>
                  <ArticleCard article={article} />
                </Row>
              ))}
            <Row>
              <a href="/articles" className="see-all-link">
                <Button>Смотреть все</Button>
              </a>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
