import React from 'react';
import ReactPageScroller from 'react-page-scroller';
import { MatchMediaHOC } from 'react-match-media';
import HomeSection from './HomeSection';
import RatingsSection from './RatingSection/RatingsSection';
import '../../styles/style.css';
import { history } from '../../index';
import AppNavigates from './AppNavigates';
import BottomNavigation from './BottomNavigation';
import SideBar from './SideBar';
import InfoSection from './InfoSection/InfoSection';
import PackagesSection from './PackagesSection/PackagesSection';
import BlogSection from './BlogSection/BlogSection';
import DevelopersSection from './DevSection/DevelopersSection';

const MainPage = ({
  actions, ratings, blog, packageTypes, currentPage, buildNumber,
}) => {
  React.useEffect(() => {
    actions.getStudentRatings();
    actions.getArticles();
    actions.getPackageTypes();
  }, [actions, actions.getStudentRatings, actions.getArticles, actions.getPackageTypes]);
  return (
    <div className="main-page-root">
      <DesktopVersion
        actions={actions}
        ratings={ratings}
        blog={blog}
        packageTypes={packageTypes.packageTypes}
        currentPage={currentPage}
        buildNumber={buildNumber}
      />
      <MobileVersion
        actions={actions}
        ratings={ratings}
        blog={blog}
        packageTypes={packageTypes.packageTypes}
        currentPage={currentPage}
        buildNumber={buildNumber}
      />
    </div>
  );
};

function Desktop({
  actions, ratings, blog, currentPage, buildNumber, packageTypes,
}) {
  return (
    <main>
      <ReactPageScroller
        renderAllPagesOnFirstRender
        animationTimer={500}
        pageOnChange={actions.handlePage}
        customPageNumber={currentPage}
      >
        <HomeSection />
        <InfoSection />
        <PackagesSection packageTypes={packageTypes} />
        <BlogSection blog={blog} />
        <RatingsSection ratings={ratings} />
        <DevelopersSection />
      </ReactPageScroller>
      <SideBar buildNumber={buildNumber} actions={actions} />
      <BottomNavigation />
      <div className="pagination-additional-class">
        <button
          className="app-bar-buttons"
          onClick={() => history.push('/login')}
        >
          Log In
        </button>
        <AppNavigates actions={actions} />
      </div>
    </main>
  );
}

function Mobile({
  actions, ratings, blog, buildNumber, packageTypes,
}) {
  return (
    <main>
      <HomeSection />
      <InfoSection />
      <PackagesSection packageTypes={packageTypes} />
      <BlogSection blog={blog} />
      <RatingsSection ratings={ratings} />
      <DevelopersSection />
      <SideBar buildNumber={buildNumber} actions={actions} />
      <BottomNavigation />
      <div className="pagination-additional-class">
        <button
          className="app-bar-buttons"
          onClick={() => history.push('/login')}
        >
          Log In
        </button>
        <AppNavigates actions={actions} />
      </div>
    </main>
  );
}

const DesktopVersion = MatchMediaHOC(Desktop, '(min-width: 820px)');
const MobileVersion = MatchMediaHOC(Mobile, '(max-width: 820px)');
export default MainPage;
