import React, { useEffect } from 'react';
import '../../styles/RatingArchive/RatingArchive.css';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import images from '../Main/Images';

export default function RatingArchive({
  students, actions, availableYears, availableMonths, year, month, ...props
}) {
  useEffect(() => {
    actions.getRatingArchive();
  }, [actions, actions.getRatingArchive, year, month]);
  return (
    <div className="rating-archive-component">
      <Container className="mt-4">
        <div className="rating-archive-img-form">
          <a href="/"><img className="rating-archive-item" src={images.logoIcon} alt="LogoIcon" /></a>
        </div>
        <Card className="mt-3">
          <Card.Body>
            <Row className="justify-content-start">
              <Col md={2} sm={12}>
                <h3 className="float-left">Победители:</h3>
              </Col>
              <Col md={3} sm={12}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    as="select"
                    onChange={({ target }) => actions.setYear(target.value)}
                    value={year}
                  >
                    {availableYears.map((year) => (
                      <option value={year} key={year}>{year}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={3} sm={12}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control
                    as="select"
                    onChange={({ target }) => actions.setMonth(target.value)}
                    value={month}
                  >
                    {availableMonths[year] && availableMonths[year].map((month) => (
                      <option value={month.code} key={month.code}>{month.display}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <StudentsTable students={students} />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

function StudentsTable({ students }) {
  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th align="left">№</th>
            <th align="left">ФИО</th>
            <th align="left">Школа</th>
            <th align="left">Количество баллов</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={student.id}>
              <td className="place-td">{index + 1}</td>
              <td>{`${student.first_name} ${student.last_name}`}</td>
              <td>{student.school_name}</td>
              <td>{student.points}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
