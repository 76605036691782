import React from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/ru';

import '../styles/App.css';

moment.locale('ru');

function App(props) {
  return (
    <div className="App">
      {props.children}
    </div>
  );
}

export default App;
