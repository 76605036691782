import React from 'react';
import '../../../styles/InfoSection/InfoSection.css';
import ReactPlayer from 'react-player';

export default function InfoSection() {
  return (
    <div className="home-container">
      <div className="component second-component">
        <div className="column-style">
          <div className="info-head-title">
            <h2 className="info-head-text"> Что такое MEMORY MEE? Подробности...</h2>
          </div>
          <div className="div-row-info-items">
            <div className="info-video-div">
              <ReactPlayer
                width="100%"
                height="100%"
                controls
                url="https://www.youtube.com/watch?v=R9lemYJ8Ozc"
              />
            </div>
            <div className="info-text-div">
              <p className="info-description_text">
                Воображение важнее, чем знания. Знания ограничены, тогда как воображение охватывает
                целый мир, стимулируя прогресс, порождая эволюцию
                (Альберт Эйнштейн)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
