import React from 'react';
import '../styles/App.css';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import Routes from '../routes/AppRoutes';

function Root(props) {
  const { store, history } = props;
  return (
    <Provider store={store}>
      <Router history={history}>
        {Routes}
      </Router>
    </Provider>

  );
}

export default Root;
