import { combineReducers } from 'redux';
import mainPage from './main/main-page';
import ratingArchive from './main/rating-archive';
import blog from './blog/blog';
import packageTypes from './main/package-types';

export default combineReducers({
  mainPage,
  ratingArchive,
  blog,
  packageTypes,
});
