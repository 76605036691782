import React from 'react';

export function ArticleCard({ article }) {
  return (
    <div className="article-card-container">
      <img
        src={article.image}
        className="article-image"
        alt="article"
      />
      <div className="article-body">
        <div className="title-container">
          <a href={`/articles/${article.id}`} className="article-title">{article.title}</a>
          <p className="article-short-description">
            {article.short_description}
          </p>
        </div>
        <div className="action-container">
          <a href={`/articles/${article.id}`}>
            <button>Читать дальше ...</button>
          </a>
        </div>
      </div>
    </div>
  );
}
