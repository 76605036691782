import { bindActionCreators } from 'redux';
import { connect as reduxConnect } from 'react-redux';
import MainPage from '../../components/Main/MainPage';
import * as studentRatings from '../../actions/main/rating-page';
import * as mainPageAction from '../../actions/main/main-page';
import { getArticles } from '../../actions/blog/blog';
import { getPackageTypes } from '../../actions/main/package_types';

const mapStateToProps = (state) => ({
  ...state.mainPage,
  blog: state.blog,
  packageTypes: state.packageTypes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...studentRatings,
    ...mainPageAction,
    getArticles,
    getPackageTypes,
  }, dispatch),
});

export default reduxConnect(mapStateToProps, mapDispatchToProps)(MainPage);
