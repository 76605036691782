import React from 'react';
import '../../styles/BottomNavigation.css';
import { MatchMediaHOC } from 'react-match-media';
import Images from './Images';

const BottomNavigation = () => (
  <div className="custom-navbar">
    <div className="horizontal-orientation">
      <img className="avatar-icon" src={Images.logoMemoryMee} alt="" />
      <img className="bottom-icons" src={Images.iconFacebook} alt="" />
      <img className="bottom-icons" src={Images.iconYoutube} alt="" />
      <img className="bottom-icons" src={Images.iconInsta} alt="" />
      <img className="bottom-icons" src={Images.iconWhatsapp} alt="" />
      <img className="bottom-icons" src={Images.shareIcon} alt="" />
    </div>
  </div>
);
export default MatchMediaHOC(BottomNavigation, '(max-width: 460px)');
