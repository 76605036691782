import iconFacebook from '../../assets/images/facebook-icon.png';
import iconYoutube from '../../assets/images/youtube-icon.png';
import iconInsta from '../../assets/images/insta-icon.png';
import iconWhatsapp from '../../assets/images/whats-app-icon.png';
import brain from '../../assets/images/memory-mee-brain.jpg';
import headRoundedIcon from '../../assets/images/side-heder-icon.png';
import shareIcon from '../../assets/images/share-icon.png';
import ratingSectionHead from '../../assets/images/head-text-of-ratings-section.png';
import whiteLine from '../../assets/images/horizontal-line.png';
import headTextBackground from '../../assets/images/head-text-background.png';
import yellowButton from '../../assets/images/button-yellow.png';
import blueButton from '../../assets/images/name-games.png';
import logoIcon from '../../assets/images/logo memory.png';
import postImg from '../../assets/images/single_post_img.jpg';
import titleBackground from '../../assets/images/title-background.png';
import homeTitleIcon from '../../assets/images/main_page_title.png';
import IconHome from '../../assets/images/big-icon-home.png';
import logoMemoryMee from '../../assets/images/logo_memoryMee.png';

export default {
  iconFacebook,
  iconYoutube,
  iconInsta,
  iconWhatsapp,
  brain,
  headRoundedIcon,
  shareIcon,
  ratingSectionHead,
  whiteLine,
  headTextBackground,
  yellowButton,
  blueButton,
  logoIcon,
  postImg,
  titleBackground,
  homeTitleIcon,
  IconHome,
  logoMemoryMee,
};
